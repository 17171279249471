html{
  min-height: 100% !important;
  height: 100%;
  background-color: #f0f0f0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root{
  height: 95%;
  width: 100%;
}

.grid-container {
  width: 100%;
  height: 95%;
  display: grid;
  grid-template-columns: 0.3fr 2.4fr 0.3fr;
  grid-template-rows: 0.6fr 1.4fr;
  grid-template-areas: "lbl_busqueda lbl_busqueda lbl_busqueda" "lbl_margen_izquierdo lbl_contenido lbl_margen_derecho";
  min-height:95vh;
  background-color: white;
}

.lbl_busqueda { grid-area: lbl_busqueda; background-color: #F0F0F0;text-align: -webkit-center;}

.lbl_margen_izquierdo { grid-area: lbl_margen_izquierdo; background-color: white;}

.lbl_margen_derecho { grid-area: lbl_margen_derecho; background-color: white;}

.lbl_contenido { grid-area: lbl_contenido; background-color: white;text-align: -webkit-center;border-radius: 5%;padding: 15px;}


.grid-container_opcion {
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 2.4fr 0.3fr;
  grid-template-rows: 0.1fr 1.9fr;
  grid-template-areas:  "lbl_busqueda_opcion lbl_busqueda_opcion lbl_busqueda_opcion" "lbl_margen_izquierdo_opcion lbl_contenido_opcion lbl_margen_derecho_opcion";
  min-height:100vh;
  background-color: white;
}

.lbl_busqueda_opcion { grid-area: lbl_busqueda_opcion; background-color: white;text-align: -webkit-center;}

.lbl_margen_izquierdo_opcion { grid-area: lbl_margen_izquierdo_opcion; }

.lbl_margen_derecho_opcion { grid-area: lbl_margen_derecho_opcion; }

.lbl_contenido_opcion { grid-area: lbl_contenido_opcion;padding: 10px; border: .0625rem solid #dadce0;border-radius: 16px;}



.promoted-search__input {
    background-color: #ffffff;
    border: none;
    border-radius: .5rem;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-sizing: border-box;
    color: #202124;
    display: block;
    font-size: 1rem;
    height: 3rem;
    padding: 0 2.5rem 0 4.5rem;
    width: 100%;
    -webkit-appearance: none;
}


.promoted-search__container {
    margin-top: 1rem;
    position: relative;
    width: 100%;
}

.promoted-search__search-button {
    left: 1.5rem;
}
.promoted-search__search-button, .promoted-search__clear-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 3rem;
    padding: 0;
    position: absolute;
    top: 0;
    width: 3rem;
}

.promoted-search__search-icon, .promoted-search__clear-icon {
    fill: #3c4043;
    height: 3rem;
    width: 1.5rem;
}
svg:not(:root) {
    overflow: hidden;
}
.img_logo{
  height: 100px;
  border-radius: 20%;
}
.img_logo_home{
  height: 40px;
}

.div_titulo{
    display: block;
    width: 96%;
    padding: 2%;
    border-bottom:  .0625rem solid #dadce0;
    color: #202124;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 0rem;
}
.div_titulo_opcion{
    display: block;
    width: 96%;
    padding: 2%;
    border: .0625rem solid #dadce0;
    color: #5f6368;
    font-family: 'Google Sans','Product Sans',Roboto,sans-serif;
    font-size: 20px;
    margin: auto;
    text-align: left;
    text-overflow: ellipsis;
    top: 0;

}
.div_opcion{
    height: 25px;
    padding: 5px;

}
.contenido_busqueda{
  position: absolute;
  z-index: 99999;
  width: 100%;
  text-align: -webkit-left;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-sizing: border-box;
  background: ivory;
  padding: 2%;
  margin-top: 8px;
}
.contenido_busqueda_children{
  padding: 5px;
}
.contenido_busqueda_children:hover {
    color: -webkit-link;
    cursor: pointer;
    background-color: #CEDEF4;
    height: 100%;
}
.lbl_como{
  color: #1967d2;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.25rem;
  padding: 0 0 1.5rem 0;
  text-align: center;
}
.lbl_footer{
  font-size: .75rem;
  line-height: 3rem;
  margin-right: 1.5rem;
  width: initial;
  text-align: center;
}
.grid-container_d {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  grid-template-rows: 1fr;
  grid-template-areas: "lbl_contenido_d lbl_opciones_d";
}

.lbl_contenido_d { grid-area: lbl_contenido_d; }

.lbl_opciones_d { grid-area: lbl_opciones_d; }
.lbl_ayuda{
  color: #3c4043;
  font-weight: 500;
}
.fixed-sidebar-container
{
  position: fixed;
}

@media (min-width: 61rem)
.primary-nav {
    display: table-cell;
    padding: 2.625rem 0 0;
}
@media (min-width: 48.1875rem)
.primary-nav {
    border: none;
    padding-top: 2.625rem;
}
@media (min-width: 61rem)
.primary-nav {
    width: 21.125rem;
}

.primary-nav {
    border: .0625rem solid #dadce0;
    border-left: none;
    border-right: none;
    box-sizing: border-box;
    padding: 0.5rem 0.5rem 0.5rem;
    background-color: #EAF1FB;
}

@media (min-width: 61rem)
.sibling-nav {
    margin-top: 0;
    min-height: 16.75rem;
}
@media (min-width: 48.1875rem)
.sibling-nav {
    width: 100%;
}
.sibling-nav {
    display: inline-block;
    margin-top: .625rem;
}
li:hover {
    color: -webkit-link;
    cursor: pointer;
    background-color: #CEDEF4;
    height: 100%;
}
.img_fondo{
  width: 210px;
}

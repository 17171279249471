.App {
  text-align: center;
}
.App{
  height: 100%;
  width: 100%;
}
.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.cssversion_menu{
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.cssversion_pw100{
  width: 100%;
}
.cssversion_pxh400{
  height:  400px;
}
.cssversion_ph100{
  height:  100%;
}
.cssversion_contentCenter{
  justify-content: center;
  align-items: center;
  display: flex;
}
